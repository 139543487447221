@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-font: "Poppins", sans-serif;
  --menu-color: #dad8d8;
  --menu-color-black: #000;
  /* --primary-btn-color: #ff7f11; */
  --primary-btn-color: #83a490;
  --primary-color: #83a490;
  --white-color: #f7f7f7;
  --black-color: #212121;
  --grey-color: #d6d6d6;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: var(--main-font);
  background-color: #000;
}

select {
  background-color: transparent;
}
select option {
  background-color: transparent;
}

button {
  outline: none;
}

input,
input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid;
}

/* my customs  */
.main-nav {
  /* background-color: var(--menu-color-black); */
  transition: 0.4s ease;
}

.mobile-nav {
  transition: 0.5s ease;
  transform: translateX(100%);
  opacity: 0;
}
.mobile-nav-show {
  transform: translateX(0);
  opacity: 1;
}
.main-nav nav a {
  transition: 0.3s ease;
}
.main-nav nav a:hover {
  color: var(--primary-btn-color);
}
.main-nav nav .active {
  color: var(--primary-btn-color);
}
/* my customs end */

.fixed-bg {
  background-image: url(./assets/fixed-bg.jpg);
}

/* Customize the scrollbar track */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: var(--grey-color); /* Change the thumb color */
  border-radius: 5px; /* Round the corners of the thumb */
}

/* Optionally, style the scrollbar track on hover */
::-webkit-scrollbar-track:hover {
  background-color: #ddd; /* Change the track color on hover */
}

.direction-rtl {
  direction: rtl;
}
.direction-ltr {
  direction: ltr;
}

.address-map {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.success-email {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s ease;
}

.show-success-email {
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: all;
}
