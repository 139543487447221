.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.brand-box {
  width: 200px;
  height: 200px;
  box-shadow: 8px 12px 32px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
}
.brand-box img {
  width: 100px; /* Set your desired width */
  height: 100px; /* Set your desired height */
  object-fit: cover;
  border-radius: 50%; /* Use 50% to create a full circle */
  display: block;
}

@media only screen and (max-width: 900px) {
}
